/* @flow */

import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import { useData, useSendMessage } from "crustate/react";
import { getCurrentPageInfo } from "state/current-page-info";
import NewsListItem, { DummyNewsListItem } from "components/NewsListItem";
import { CurrentPageInfoData } from "data";
import { stutter } from "helpers/animation";
import Wrapper from "components/Wrapper";
import ErrorView from "components/ErrorView";
import { Title } from "components/UiComponents";
import HeroIntro from "components/HeroIntro";

import styles from "./styles.scss";
import cn from "classnames";

const NUM_DUMMY_CARDS = 6;

const NewsListView = (): React$Node => {
  const sendMessage = useSendMessage();
  const { content: { newslistview: content, heroIntroView } } = useContext(StoreInfoContext);
  const currentPageInfoData = useData(CurrentPageInfoData);
  const _stutter = stutter(100, 100);

  useEffect(() => {
    sendMessage(getCurrentPageInfo("NEWSVIEW"));
  }, []);

  const newsCurrentInfo =
  currentPageInfoData.state === "LOADED" &&
  currentPageInfoData.data.newsview &&
  currentPageInfoData.data.newsview.length > 0 ? currentPageInfoData.data.newsview : [];

  if (currentPageInfoData.state === "ERROR") {
    return <ErrorView />;
  }

  // TODO: add "load more"-button and make use of content.loadMoreText

  return (
    <>
      <Helmet title={content.pageTitle ?? ""} />
      {Boolean(heroIntroView.toggle) &&
        <HeroIntro title={content.heading ?? ""} />
      }
      <Wrapper variant="pageWrapper" className="awardit-newsListView awardit-pageWrapper">
        {Boolean(heroIntroView.toggle) === false &&
          <div className={styles.intro}>
            {content.heading &&
              <Title>{content.heading}</Title>
            }
          </div>
        }
        <div className={cn(styles.items, "awardit-newsListViewItems")}>
          {newsCurrentInfo.map((x, i) => (
            <div
              key={x.title}
              style={{ animationDelay: _stutter(i) }}
              className={cn(styles.item, "awardit-newsListViewItem")}
            >
              <NewsListItem key={x.title} item={x} />
            </div>
          ))
          }

          {(currentPageInfoData.state === "LOADED" &&
            newsCurrentInfo.length === 0 && content.noItemsText) && (
            <div className={styles.no_news}>
              <h2>{content.noItemsText}</h2>
            </div>
          )}

          {currentPageInfoData.state === "LOADING" &&
            [...new Array(NUM_DUMMY_CARDS)].map((x, i) => (
              <div
                key={"dummyNews_" + i}
                style={{ animationDelay: _stutter(i) }}
                className={styles.item}
              >
                <DummyNewsListItem />
              </div>
            ))
          }
        </div>
      </Wrapper>
    </>
  );
};

export default NewsListView;
