/* @flow */

import type { InfoBlock } from "shop-state/types";

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { formatTimestampToLocaleDate } from "helpers/locale-utils";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { AnalyticsContext } from "@crossroads/analytics";

import styles from "./styles.scss";
import cn from "classnames";

type NewsListItemProps = {
  item: InfoBlock,
};

const NewsListItem = ({
  item: {
    title,
    content,
    id,
    fromDate,
  },
}: NewsListItemProps): React$Node => {
  const t = useTranslate();
  const { info, routes } = useContext(StoreInfoContext);
  const gaContext = useContext(AnalyticsContext);
  const dateFormat = formatTimestampToLocaleDate(info.locale);
  const publishedAt = fromDate ? dateFormat(fromDate) : null;

  const gaRegister = () => {
    gaContext.registerContentClick(`News: ${title ?? ""}`, id.toString());
  };

  const url = routes.newsListView && routes.newsListView.url && title ?
    `${routes.newsListView.url}/${encodeURIComponent(title)}` : "";

  return (
    <Link
      className={styles.block}
      to={url}
      onClick={gaRegister}
    >
      <div className={styles.shadow} />
      <div className={styles.contentWrapper}>
        <h2 className={styles.title}>{title}</h2>
        {/* eslint-disable react/no-danger */}
        <div
          className={cn(styles.content, "awardit-newsListItemContent")}
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {/* eslint-enable react/no-danger */}
        <div className={styles.itemFooter}>
          <p>{t("EARN.SHOW_MORE")}</p>
          <p className={styles.date}>{publishedAt ?? ""}</p>
        </div>
      </div>
    </Link>
  );
};

export const DummyNewsListItem = (): React$Node => (
  <div className={styles.dummy_block}>
    <div className={styles.dummy_top}>
      <div className={styles.dummy_date} />
    </div>
    <div className={styles.dummy_title} />
  </div>
);

export default NewsListItem;
